import * as React from 'react'
import Lecture from './Lecture'
import "./LectureCard.css"

const Card = ({ content }) => (
  <div
    className="content"
    style={{
      "--hsl-light": `var(--hsl-rainbow--${content.color}-light)`,
      "--hsl": `var(--hsl-rainbow--${content.color})`,
      "--hsl-dark": `var(--hsl-rainbow--${content.color}-dark)`,
    }}
  >
    <section className="lecture-card">
      <div className="lecture-card-illustration lecture-card-illustration--large">
        <img
          src={`/illustrations/hooonk/${content.illustration}.png`}
          alt={`${content.title} illustration`}
          height={300}
          width={300}
        />
      </div>
      <div className="lecture-card-description">
        <div className="lecture-card-title">
          <div className="lecture-card-illustration lecture-card-illustration--small">
            <img
              src={`/illustrations/hooonk/${content.illustration}.png`}
              alt={`${content.title} small illustration`}
              height={60}
              width={60}
            />
          </div>
          <h3>{content.title}</h3>
        </div>
        <p>{content.description}</p>
      </div>
      <div className="lecture-card-lectures">
        <h4>Articles</h4>
        <ul>
          {content.lectures.map((lecture, index) => (
            <Lecture
              lecture={lecture}
              // Start with "1"
              index={index + 1}
              key={`${index}-${lecture.name}`}
            />
          ))}
        </ul>
      </div>
    </section>
  </div>
)

export default Card
